@import './src/scss/variables';
@import './src/scss/app';

.my-flex-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;

  &.china .my-flex-box {
    background-color: #edfae0;
  }

  .my-flex-box {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 1rem;
//     margin: 10px 5px;
//     flex: 0 1 225px;
//     align-self: center;
//     text-align: center;

    &.current {
      background-color: #c4c4ff;
    }

    img {
      display: block;
      margin: auto;
      width: 60px;
      height: 60px;
    }
  }
}

.my-flex-cont-graph {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  //width: 360px;
  margin: auto;

  .my-flex-box-graph {
    align-self: stretch;
    text-align: left;

    .item {
      position: relative;
      padding: 5px;
      border-bottom: 1px solid;

      .title {
        vertical-align: middle;
        position: relative;
        //font-weight: bold;
        z-index: 1;
      }

      .bar {
        position: absolute;
        height: 40px;
        top: 0;
        left: 0;

        @for $i from 1 through 100 {
          &.percent-#{$i} {
            background-image: linear-gradient(to top, #008138, #3a9a39, #66b936, #9de031, #c6ee21, #dbe508, #eedb00, #ffd000, #ffad00, #ff8700, #ff5b00, #ff0000);
            background-size:100% 100000%;
            background-position: 0 #{$i + '%'};
            width: #{$i + '%'};
          }
        }
      }
    }
  }
}

.error-cell {
  background-color: red;
}